import React, { useEffect, useRef } from 'react';

interface TradesListProps {
    trades: any[];
}

const TradesList: React.FC<TradesListProps> = ({ trades }) => {
    const prevTradesRef = useRef<any[]>([]);

    useEffect(() => {
        // Request permission on mount
        if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
            Notification.requestPermission();
        }

        // Detect new trades
        if (prevTradesRef.current.length < trades.length) {
            const newTrades = trades.slice(prevTradesRef.current.length);

            newTrades.forEach((trade) => {
                if (Notification.permission === 'granted') {
                    new Notification('New Trade Alert!', {
                        body: `Trade: ${trade.title}, Side: ${trade.side}, Size: $${trade.usdcSize.toFixed(2)}`,
                        // icon: 'path/to/icon.png', // Add an icon if desired
                    });
                }
            });
        }

        // Update the previous trades reference
        prevTradesRef.current = trades;
    }, [trades]);

    return (
        <div>
            {trades.length > 0 ? (
                trades.map((trade, index) => (
                    <div key={index} className="flex flex-col items-center mb-6 border-b pb-4">
                        <span className="text-yellow-500 text-2xl mb-2">🔔</span>
                        <div className="text-center mb-4">
                            <p className="font-bold text-lg text-gray-800">
                                Title: <span className="font-normal">{trade.title}</span>
                            </p>
                        </div>
                        <div className="flex justify-around w-full text-center">
                            <div className="flex flex-col items-center">
                                <p className="font-semibold text-gray-700">Side</p>
                                <p
                                    className={`font-bold ${
                                        trade.side === 'BUY' ? 'text-green-600' : 'text-red-600'
                                    }`}
                                >
                                    {trade.side === 'BUY' ? 'Buy 🟢' : 'Sell 🔴'}
                                </p>
                                <p className="text-sm text-gray-600">Outcome: {trade.outcome}</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <p className="font-semibold text-gray-700">USDC Size</p>
                                <p className="font-bold text-gray-800">${trade.usdcSize.toFixed(2)}</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <p className="font-semibold text-gray-700">Price</p>
                                <p className="font-bold text-gray-800">${trade.price.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-gray-600 text-center">No trades available.</p>
            )}
        </div>
    );
};

export default TradesList;
