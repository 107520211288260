import React, { useState } from 'react';
import AddUserForm from './AddUserForm';
import UserCard from './UserCard';
import InfoButton from './InfoButton';

const TrackUsersComponentConnector: React.FC = () => {
    const [users, setUsers] = useState<string[]>([]);

    const addUser = (address: string) => {
        if (!users.includes(address)) {
            setUsers((prevUsers) => [...prevUsers, address]);
        }
    };

    const removeUser = (address: string) => {
        setUsers((prevUsers) => prevUsers.filter((user) => user !== address));
    };

    return (
        <div className="bg-gray-50 min-h-screen p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">Polymarket Trade Monitor</h1>
            <div className="flex items-center justify-center gap-x-2 mb-6">
            <h5 className="text-1xl font-bold text-gray-400 text-center">
                Track user trades
            </h5>
            <InfoButton
                title="Where can I find the user Address?"
                description="You can find user profiles directly on the leaderboard. User addresses are displayed under the usernames. For example, when accessing a user profile, you can identify the top-performing accounts based on their performance metrics available on the Polymarket leaderboard."
            />
            </div>

            
            
            
            <AddUserForm onAddUser={addUser} />
            <div className="max-w-4xl mx-auto">
                {users.map((userAddress) => (
                    <UserCard key={userAddress} userAddress={userAddress} onRemoveUser={removeUser} />
                ))}
            </div>
        </div>
    );
};

export default TrackUsersComponentConnector;
