import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { POLYMKT_API_URL, POLLING_INTERVAL } from '../config/constants';
import TradesList from './TradeList';
interface UserCardProps {
    userAddress: string;
    onRemoveUser: (address: string) => void;
}

const UserCard: React.FC<UserCardProps> = ({ userAddress, onRemoveUser }) => {
    const [userName, setUserName] = useState<string>(''); // Store the user's name
    const [trades, setTrades] = useState<any[]>([]); // Store new trades
    const [isMonitoring, setIsMonitoring] = useState<boolean>(false);

    let lastTradeId: string | null = null;
    let intervalId: NodeJS.Timeout | null = null;

    const fetchUserTrades = async (address: string) => {
        try {
            const url = `${POLYMKT_API_URL}?user=${address}&limit=1&offset=0`;
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching trades:', error);
            return [];
        }
    };

    const fetchUserName = async () => {
        try {
            const tradesData = await fetchUserTrades(userAddress);
            if (tradesData && tradesData.length > 0) {
                const userNameFromTrade = tradesData[0]?.name || '';
                setUserName(userNameFromTrade);
            }
        } catch (error) {
            console.error('Error fetching user name:', error);
        }
    };

    const startMonitoring = () => {
        fetchUserName();
        setIsMonitoring(true);

        intervalId = setInterval(async () => {
            try {
                const tradesData = await fetchUserTrades(userAddress);
                if (tradesData && tradesData.length > 0) {
                    const mostRecentTrade = tradesData[0];

                    if (
                        mostRecentTrade.transactionHash !== lastTradeId &&
                        lastTradeId !== null
                    ) {
                        setTrades((prevTrades) => [mostRecentTrade, ...prevTrades]);
                        lastTradeId = mostRecentTrade.transactionHash;
                    } else if (lastTradeId === null) {
                        lastTradeId = mostRecentTrade.transactionHash;
                    }
                }
            } catch (error) {
                console.error('Error monitoring trades:', error);
            }
        }, POLLING_INTERVAL);
    };

    const stopMonitoring = () => {
        setIsMonitoring(false);
        if (intervalId) clearInterval(intervalId);
    };

    const toggleMonitoring = () => {
        if (isMonitoring) {
            stopMonitoring();
        } else {
            startMonitoring();
        }
    };

    useEffect(() => {
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, []);

    return (
        <div className="bg-white shadow-md rounded-lg border border-gray-300 p-4 mb-6">
            <div className="flex justify-between items-center">
                <div>
                    <p className="text-gray-800 font-bold">
                        User: <span className="font-normal">{userName || 'Unknown'}</span>
                    </p>
                    <p className="text-gray-600">
                        Address: <span className="font-normal">{userAddress}</span>
                    </p>
                </div>
                <div className="flex gap-2">
                    <button
                        onClick={toggleMonitoring}
                        className={`px-4 py-2 rounded-lg text-white font-bold ${
                            isMonitoring
                                ? 'bg-red-600 hover:bg-red-500 focus:ring-4 focus:ring-red-400'
                                : 'bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:ring-blue-400'
                        }`}
                    >
                        {isMonitoring ? 'Stop Monitoring' : 'Start Monitoring'}
                    </button>
                    <button
                        onClick={() => onRemoveUser(userAddress)}
                        className="px-4 py-2 bg-gray-600 text-white font-bold rounded-lg hover:bg-gray-500"
                    >
                        Remove
                    </button>
                </div>
            </div>
            <TradesList trades={trades} />
        </div>
    );
};

export default UserCard;
