import React from 'react';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { Buffer } from 'buffer'; // Polyfill for Buffer
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
// import WalletConnect from "@walletconnect/web3-provider";
window.Buffer = Buffer;

export const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Web3 Modal Demo",
      infuraId: '6025691752024afe828efd6cb34f5175', // Replace with your Infura ID
    },
  },
};

export async function connectWallet() {
  try {
    const web3modal = new Web3Modal({
      network: "mainnet", // Or "matic" for Polygon
      cacheProvider: false,
      providerOptions,
    });

    const web3ModalInstance = await web3modal.connect();

    // Use Web3Provider for ethers@5
    const provider = new ethers.providers.Web3Provider(web3ModalInstance);
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    console.log('User Address:', address);
    console.log('Signer:', signer);

    return { provider, signer, address };
  } catch (error) {
    console.error("Could not connect to wallet:", error);
    return null;
  }
}
