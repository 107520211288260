import React, { useState, useEffect, useRef } from 'react';

interface InfoButtonProps {
  title: string; // Title for the popup
  description: string; // Description for the popup
}

const InfoButton: React.FC<InfoButtonProps> = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={popupRef}>
      {/* Info Button */}
      <button
        onClick={togglePopup}
        className="w-6 h-6 flex items-center justify-center text-white bg-gray-300 rounded-full border border-gray-300 shadow-md hover:bg-gray-600"
        aria-label="Info"
      >
        i
      </button>

      {/* Info Popup */}
      {isOpen && (
        <div
          className="absolute top-8 left-1/2 transform -translate-x-1/2 bg-white text-black text-sm rounded-md shadow-lg border border-gray-200 px-4 py-2 w-64 z-10"
          role="tooltip"
        >
          <h3 className="font-bold text-lg mb-2">{title}</h3>
          <p className="text-gray-700">{description}</p>
        </div>
      )}
    </div>
  );
};

export default InfoButton;
