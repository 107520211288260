import React, { useState } from "react";
import { connectWallet } from "../services/walletService";
import { getBalance } from "../services/balanceService";
import ArbitrageLogoComponent from "./ArbitrageLogoComponent";
const Header: React.FC = () => {
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const [balance, setBalance] = useState<string>("");

  const handleLogin = async () => {
    const walletInfo = await connectWallet();
    console.log(walletInfo);
    if (walletInfo) {
      setUserAddress(walletInfo.address);
      const userBalance = await getBalance(walletInfo.provider, walletInfo.address);
      setBalance(userBalance);
    }
  };

  return (
    
    <header className="flex items-center justify-between px-6 py-4 bg-gray-100 shadow-lg">
      {/* Logo Section */}
      {/* <div className="flex items-center space-x-2">
        <img
          src="/images/logo_arbitrageon_solid_color.png"
          alt="ArbitrageON Logo"
          className="h-12 object-contain"
        />
      </div> */}
      {/* Arbitrage Logo */}
      {/* <ArbitrageLogoComponent /> */}
      <ArbitrageLogoComponent />
      {/* <div className="flex justify-between items-right max-w-screen-xl mx-auto">
        <ArbitrageLogoComponent />
      </div> */}

      {/* Wallet Section */}
      {/* <div className="flex items-center space-x-4">
        {userAddress ? (
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2 text-sm font-bold text-gray-700">
              <div className="flex items-center space-x-1 bg-white border-2 border-gray-300 px-3 py-1 rounded-full shadow-md">
                <img
                  src="https://cryptologos.cc/logos/ethereum-eth-logo.png"
                  alt="Ethereum"
                  className="w-5 h-5"
                />
                <span>Ethereum</span>
              </div>
            </div>
            <div className="flex items-center space-x-2 bg-white border-2 border-gray-300 px-3 py-1 rounded-full shadow-lg text-sm font-bold text-gray-700">
              <span>{balance || "0"} ETH</span>
            </div>
            <div className="flex items-center space-x-2 bg-white border-2 border-red-300 px-3 py-1 rounded-full shadow-lg text-sm font-bold text-red-700">
              <span>{userAddress.slice(0, 6)}...{userAddress.slice(-4)}</span>
            </div>
          </div>
        ) : (
          <button
            onClick={handleLogin}
            className="px-4 py-2 bg-blue-600 text-white font-bold rounded-lg shadow-lg hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-400"
          >
            Connect Wallet
          </button>
        )}
      </div> */}
    </header>
  );
};

export default Header;
