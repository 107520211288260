import React, { useState } from 'react';

interface AddUserFormProps {
    onAddUser: (address: string) => void;
}

const AddUserForm: React.FC<AddUserFormProps> = ({ onAddUser }) => {
    const [userAddress, setUserAddress] = useState<string>('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!userAddress) {
            alert('Please enter a valid user address.');
            return;
        }
        onAddUser(userAddress);
        setUserAddress('');
    };

    return (
        <form onSubmit={handleSubmit} className="bg-white p-4 rounded-lg shadow-md border border-gray-300 w-96 mx-auto mb-6">
            <label className="flex flex-col">
                <span className="text-gray-700 font-medium mb-2">User Address:</span>
                <input
                    type="text"
                    value={userAddress}
                    onChange={(e) => setUserAddress(e.target.value)}
                    className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-200 mb-4"
                />
            </label>
            <button
                type="submit"
                className="px-6 py-2 bg-green-600 text-white font-bold rounded-lg shadow-lg hover:bg-green-500 focus:outline-none focus:ring-4 focus:ring-green-400 w-full"
            >
                Add User
            </button>
        </form>
    );
};

export default AddUserForm;
