import React, { useState } from "react";

const ArbitrageLogoComponent: React.FC = () => {
  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn((prevState) => !prevState);
  };

  return (
    <div className="flex items-center">
      {/* Arbitrage Text */}
      <span className="text-xl font-bold text-black mr-1">ARBITRAGE</span>
      {/* Toggle Button */}
      <div
        className={`relative flex items-center w-12 h-7 rounded-full cursor-pointer transition-colors duration-300 ${
          isOn ? "bg-gray-300" : "bg-green-300"
        }`}
        onClick={handleToggle}
      >
        {/* Circle */}
        <div
          className={`absolute top-1 left-1 w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
            isOn ? "translate-x-5" : ""
          }`}
        ></div>
        {/* N/O Text */}
        <span
          className={`absolute right-2 text-xl font-bold text-black transition-opacity duration-300 ${
            isOn ? "opacity-0" : "opacity-100"
          }`}
        >
          N
        </span>
        <span
          className={`absolute right-2 text-base font-bold text-black transition-opacity duration-300 ${
            isOn ? "opacity-100" : "opacity-0"
          }`}
        >
        </span>
      </div>
    </div>
  );
};

export default ArbitrageLogoComponent;
