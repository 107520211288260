import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
// import ClobClientDemo from './components/InitializeClobClient';
// import TradeMonitor from './components/TradeMonitor';
import TrackUsersComponentConnector from './components/TrackUsersComponentConnector';
import ReactGA from "react-ga4";


function App() {

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: location.pathname });
  // }, [location]);


  return (
    <div className="App">
      <Header />
          {/* <ClobClientDemo/> */}
      < TrackUsersComponentConnector/>
    </div>
  );
}

export default App;
